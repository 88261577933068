import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/templates/article-page-layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Intro = makeShortcode("Intro");
const Body = makeShortcode("Body");
const Youtube = makeShortcode("Youtube");
const Outro = makeShortcode("Outro");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Intro mdxType="Intro">
      <p>{`以下の動画がとても良かったので見直しつつメモしている。ほぼ自分のメモ用。`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://youtu.be/NeTrh3TOpCA",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`EDM Tips: Interview with Grammy nominee James Wiltshire`}</a></li>
      </ul>
    </Intro>
    <Body mdxType="Body">
      <h2 {...{
        "id": "この動画は何"
      }}>{`この動画は何`}</h2>
      <Youtube url="https://youtu.be/NeTrh3TOpCA" mdxType="Youtube" />
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://en.wikipedia.org/wiki/Freemasons_(DJs)",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`James Wiltshire`}</a>{`という、なんかとても有名らしいダンスミュージック作る人`}</li>
        <li parentName="ul">{`この人にインタービューしてる動画`}</li>
      </ul>
      <p>{`音楽に関すること自体のアンサーも面白いが、最終的に人生の意味とはみたいな話になっている。全体的に普遍的なクリエイティブについての話という側面がある。`}</p>
      <h2 {...{
        "id": "プロダクションでのトラック制作はどのような利点があるか"
      }}>{`プロダクションでのトラック制作はどのような利点があるか`}</h2>
      <ul>
        <li parentName="ul">{`人によって物事の見方は非常に異なる`}</li>
        <li parentName="ul">{`一つの部屋に別のメンバーがいて一緒に作業をするということで、相互作用がある`}</li>
        <li parentName="ul">{`例えば自分がやっていることについて、もう片方のメンバーが、自分が気付かなかった良い点を取り上げることがある`}</li>
        <li parentName="ul">{`とか、異なるアイデアのセットを持っていたりする`}</li>
        <li parentName="ul">{`全く異なるマナーの制作に触れることにも意味がある`}</li>
        <li parentName="ul">{`Russel とは長い時間を共にした。その時間は、自分が全く想像していなかった視点を自分にもたらした`}</li>
        <li parentName="ul">{`物事を、他人の視点で見る目を持つ必要がある、ただし当然、時にはそれが衝突することもある`}</li>
        <li parentName="ul">{`世の中でベストなレコードと言われているものをつっこんで聞いていくと、そういうのが分かったりする`}</li>
        <li parentName="ul">{`Quincy Jones のアルバムを聞けば、彼の周りの人々が彼に影響を与えていることが分かる`}</li>
        <li parentName="ul">{`よく寝て自分がクリエイティブになる時間を見つけよ、それが最も重要。例えば早起きしてシャワー浴びてるときとか最高よ`}</li>
      </ul>
      <h2 {...{
        "id": "loopedから抜け出すにはどうすればよいのか"
      }}>{`“looped”から抜け出すにはどうすればよいのか`}</h2>
      <p>{`※ loop には 2 つの意味がある: トラックにおける繰り返しのループと、`}<a parentName="p" {...{
          "href": "https://en.wikipedia.org/wiki/Writer%27s_block",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Writer's Block`}</a>{`のこと`}</p>
      <ul>
        <li parentName="ul">{`PC の前から離れろ`}</li>
        <li parentName="ul">{`例えばとある長さのループで詰まってたら、それを 8 回コピーし、バリエーションを作ってみる。`}</li>
        <li parentName="ul">{`そして PC から離れ、それぞれを聞く`}</li>
        <li parentName="ul">{`そしたら初めよりもベターなものができてる。これは確実。`}</li>
        <li parentName="ul">{`他の自分の好きなアーティストとかジャンルの曲を、紙に書きおこせ`}</li>
        <li parentName="ul">{`自分の耳で数回聞いてアレンジを紙に書き起こす`}</li>
        <li parentName="ul">{`これができると視覚的にその楽曲を理解できる`}</li>
        <li parentName="ul">{`時間を横軸に、どこで盛り上がってるかをグラフみたいして書け`}</li>
        <li parentName="ul">{`町に出て白紙にアレンジを書き起こし、どこをどうしたら良くなると思うかを書け`}</li>
        <li parentName="ul">{`ここのベースがどうなるだとか、ここにあれを入れたほうがいいかも見たいのを紙にかけ`}</li>
        <li parentName="ul">{`PC に向かって戦ってるより遥かにクリエイティブになれる`}</li>
        <li parentName="ul">{`ほんとんどの人は、DAW に向かっている時よりも、それまでに使い慣れているペンと紙を使っている方が、脳がクリエイティブになれる`}</li>
        <li parentName="ul">{`ペンと紙は 3,4 歳の頃から父ちゃん母ちゃんの絵を書く、そういうときから使い慣れてる、そういうもの。`}</li>
        <li parentName="ul">{`そういう使い慣れた道具を使うことで、自分の考えている、クリエイティブなアイデアを、ペンと紙を使えば、ほとんど自動で表現することが出来る`}</li>
        <li parentName="ul">{`誰かが見てるかなんて気にする必要ない、書いたものを壁やらにピンでとめておいて考えたらいい`}</li>
        <li parentName="ul">{`そして、そうやって自分が考えるアレンジができたら初めて PC に向かう。そしたらあとは自分が紙に書いた通りに曲を作ればいい、どこにボーカルをいれるとかどうとか、既にフレームワークができてる。あとはやるだけ。`}</li>
        <li parentName="ul">{`ただし多くの人は先に進めない。なぜか。その理由として大きいのは、選択肢が多すぎるから。`}</li>
        <li parentName="ul">{`どのプラグインを使うとかどうとか、選択肢が多すぎる。自分はこれを Choice Paralysis と呼んでる。`}</li>
        <li parentName="ul">{`無数のプラグイン、パッチ。どうしたらいい？`}</li>
        <li parentName="ul">{`でも紙書いてたらどうか、もう既に出来てる。紙にまとめてる時点で Choice Paralysis を乗り越えてる`}</li>
        <li parentName="ul">{`その段階であれば、ここをどう変えようなどとアイデアを試せばいい。そしてトラックを完成させれば良い。`}</li>
        <li parentName="ul">{`自分が 90 年代にダンスミュージックを作り出したときは、いわばラッキーだったと言える`}</li>
        <li parentName="ul">{`今のように無数のソフトウェアやハードウェアはなかったので、Choice がそもそも少ない`}</li>
        <li parentName="ul">{`そしてスタジオでしかトラックを作れなかった。スタジオを借りるにはコストがかかる。スタジオで 8 バーのループで止まってそこから先に進まない人などいない`}</li>
      </ul>
      <p>{`ここまでで〜25:00 ぐらいまでの内容。上記以外にも、現在の自分自身が、多くの人とのか関わりによって成り立っているという話を多くしている。`}</p>
      <h2 {...{
        "id": "choice-paralysis-について"
      }}>{`Choice Paralysis について`}</h2>
      <p>{`現代は選択肢が多すぎることについては以下の動画でも話されていた。`}</p>
      <Youtube url="https://youtu.be/H9WLPlwUlfo" mdxType="Youtube" />
      <p>{`この動画で先生をやってる人もまたテクノ黎明期からトラック制作をしている人で、はじめて親にシンセサイザーを買ってもらった時のことや、その後少しずつ機材が増えていって、制限された環境の中で作ることの重要さを話している。`}</p>
    </Body>
    <Outro mdxType="Outro">
      <p>{`続きもまた書き起こす。たぶん。`}</p>
    </Outro>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      